<template>
  <div class="space-y-4">
    <asom-card>
      <div class="py-5">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Item Type Information
        </h3>
      </div>
      <asom-alert v-if="error" variant="error" :error-message="error" />
      <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
        <div class="grid md:grid-cols-2">
          <asom-form-field label="Inventory Type Name">
            <p class="font-semibold">
              {{ get(itemDetails, "inventoryTypeName", "-") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Inventory Group">
            <p class="font-semibold">
              {{ get(itemDetails, "inventoryGroup", "-") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Threshold Quantity">
            <p class="font-semibold">
              {{ get(itemDetails, "quantity", "-") }}
            </p>
          </asom-form-field>
        </div>
      </div>
      <div class="col-span-2 flex justify-end space-x-4 pt-8">
        <asom-button text="Back" variant="secondary" @click="$router.go(-1)" />
      </div>
    </asom-card>
  </div>
</template>

<script>
import get from "lodash.get";
import { getInventoryTypeThresholdDetails } from "../../../../services/inventory.service";

export default {
  name: "ViewFirstAidBox",
  data() {
    return {
      itemDetails: {
        inventoryTypeName: get(this.$route.params, "inventoryTypeName", "-"),
        inventoryGroup: get(this.$route.params, "inventoryGroup", "-"),
        quantity: get(this.$route.params, "quantity", "-"),
      },
      error: null,
      isLoading: false,
    };
  },
  mounted() {
    this.getDetails();
  },
  methods: {
    get,
    async getDetails() {
      this.isLoading = true;
      const result = await getInventoryTypeThresholdDetails({
        inventoryTypeThresholdId: get(
          this.$route.params,
          "inventoryTypeThresholdId"
        ),
      });
      if (result.success) {
        this.setFormData(result.payload.model);
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    setFormData(data) {
      this.itemDetails.inventoryTypeName = get(data, "inventoryTypeName");
      this.itemDetails.inventoryGroup = get(data, "inventoryGroup");
      this.itemDetails.quantity = get(data, "quantity");
    },
  },
};
</script>
